import { doc, collection, onSnapshot, deleteDoc, updateDoc, query, orderBy } from 'firebase/firestore'

const state = () => ({
  allLocations: null,
  locationsListenerUnsub: null
})

const getters = {
  allLocations: state => state.allLocations,
  locationById: (state) => (id) => {
    if (state.allLocations) {
      const targetLocaction = state.allLocations.find(location => location.id === id)
      if (targetLocaction) {
        return targetLocaction.name
      } else {
        return null
      }
    } else {
      return null
    }
  },
  locationsListenerUnsub: state => state.locationsListenerUnsub
}

const mutations = {
  setAllLocations (state, val) {
    state.allLocations = val
  },
  setLocationsListenerUnsub (state, val) {
    state.locationsListenerUnsub = val
  }
}

const actions = {
  locationsListener ({ commit, state, rootState }) {
    if (state.locationsListenerUnsub != null) {
      return
    }

    const q = query(collection(rootState.firestore, 'locations'), orderBy('order'))

    const unsub = onSnapshot(q, (snapshot) => {
      const locations = []
      snapshot.forEach((location) => {
        const copy = location.data()
        copy.id = location.id
        locations.push(copy)
      })
      commit('setAllLocations', locations)
    })

    commit('setLocationsListenerUnsub', unsub)
  },

  stopLocationsListener ({ state, commit }) {
    if (state.locationsListenerUnsub) state.locationsListenerUnsub()
    commit('setLocationsListenerUnsub', null)
  },

  async firestoreDeleteLocation ({ rootState }, locationId) {
    console.log('Deleting location', locationId)
    await deleteDoc(doc(rootState.firestore, 'locations', locationId))
  },

  async firestoreUpdateLocation ({ rootState }, location) {
    const { name, order, group } = location
    await updateDoc(doc(rootState.firestore, 'locations', location.id), { name, order, group })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
