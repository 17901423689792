<template>
  <v-dialog v-model="shown" width="700">
    <v-card>
      <v-card-title class="mb-5 primary white--text">
        <h2 class="my-5">Update Location</h2>
      </v-card-title>
      <v-card-text>
        <v-textarea v-model="targetLocation.name" label="Location details" />
        <v-select
          v-model="targetLocation.group"
          hint="Assign this location to a group for the schedule grid view. (Optional)"
          persistent-hint
          :items="[{ text: 'Group 1', value: 'group-1' }, { text: 'Group 2', value: 'group-2'}, { text: 'Group 3', value: 'group-3' }]"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="shown = false"
          color="error"
          large
          block
        >
          Cancel
        </v-btn>
        <v-btn
          @click="updateLocation"
          color="accent"
          large
          block
        >
          Update Location
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  data: () => ({
    targetLocation: {
      group: 'group-1'
    },
    shown: false
  }),
  computed: {
    ...mapState(['firestore', 'globalSettings'])
  },
  mounted () {
    this.$root.$on('showLocationEditor', (location) => {
      this.targetLocation = location
      if (!this.targetLocation.group) this.targetLocation.group = 'group-1'
      this.shown = true
    })
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),
    ...mapActions(['firestoreUpdateLocation']),
    async updateLocation () {
      try {
        await this.firestoreUpdateLocation(this.targetLocation)
        this.setGlobalNotification({ val: 'Location updated.' })
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error updating location.', type: 'error' })
      }
      this.shown = false
    }
  }
}
</script>
